var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Loading", {
        attrs: { active: _vm.salvandoApresentante, "is-full-page": true }
      }),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            { staticClass: "mb-3", attrs: { align: "left", justify: "left" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" mdi-application-edit-outline ")]
                  ),
                  _vm._v("Apresentantes > Gerenciar Apresentantes ")
                ],
                1
              )
            ],
            1
          ),
          _vm.isPartner !== 1
            ? _c(
                "v-card",
                {
                  staticClass: "pa-3 mb-4",
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.aplicarFiltros.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-3 d-flex align-center" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row pa-0",
                          attrs: {
                            cols: "4",
                            xs: "6",
                            sm: "6",
                            md: "4",
                            lg: "4"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "pa-0 ma-0",
                            attrs: {
                              name: "filtro",
                              outlined: "",
                              label: "Buscar Apresentante",
                              type: "text",
                              "hide-details": "",
                              dense: "",
                              disabled: _vm.loading,
                              "data-cy": "filtroApresentante"
                            },
                            model: {
                              value: _vm.filtroApresentante,
                              callback: function($$v) {
                                _vm.filtroApresentante = $$v
                              },
                              expression: "filtroApresentante"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ml-2", attrs: { md: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.filtrosSelect,
                              label: "Filtros",
                              "item-text": "name",
                              "item-value": "value"
                            },
                            on: { change: _vm.listarApresentantes },
                            model: {
                              value: _vm.filterSelected,
                              callback: function($$v) {
                                _vm.filterSelected = $$v
                              },
                              expression: "filterSelected"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-end align-center pa-0" },
                        [
                          _vm.subDomain === "experience"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "blue white--text",
                                    "data-cy": "criarApresentanteExperience",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.dialogCriarApresentanteExperience = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Criar Apresentante Experience "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("add")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "green white--text",
                                "data-cy": "criarApresentante",
                                disabled: _vm.loading
                              },
                              on: { click: _vm.criarApresentante }
                            },
                            [
                              _vm._v(" Criar novo Apresentante "),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagem
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c("v-data-table", {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.apresentantes,
              loading: _vm.loading,
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: "Apresentantes por página:"
              },
              "item-key": "usuarios",
              search: _vm.filtroApresentante
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.cra_nacional`,
                  fn: function({ item }) {
                    return [
                      _c("v-switch", {
                        attrs: { readonly: "", inset: "" },
                        model: {
                          value: item.cra_nacional,
                          callback: function($$v) {
                            _vm.$set(item, "cra_nacional", $$v)
                          },
                          expression: "item.cra_nacional"
                        }
                      })
                    ]
                  }
                },
                {
                  key: `item.dt_liberacao_estados`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.dt_liberacao_estados
                              ? new Date(
                                  new Date(item.dt_liberacao_estados).setDate(
                                    new Date(
                                      item.dt_liberacao_estados
                                    ).getDate() + 1
                                  )
                                ).toLocaleDateString()
                              : ""
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: `item.st_ativo`,
                  fn: function({ item }) {
                    return [
                      _c("v-switch", {
                        style: {
                          color: item.st_ativo != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.st_ativo != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.st_ativo,
                          callback: function($$v) {
                            _vm.$set(item, "st_ativo", $$v)
                          },
                          expression: "item.st_ativo"
                        }
                      })
                    ]
                  }
                },
                {
                  key: `item.st_ativo_comando`,
                  fn: function({ item }) {
                    return [
                      item.st_ativo_comando === 0
                        ? _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-pause-circle-outline")
                          ])
                        : _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-play-circle-outline")
                          ])
                    ]
                  }
                },
                {
                  key: `item.st_ativo_remessa`,
                  fn: function({ item }) {
                    return [
                      item.st_ativo_remessa === 0
                        ? _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-pause-circle-outline")
                          ])
                        : _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-play-circle-outline")
                          ])
                    ]
                  }
                },
                {
                  key: `item.st_ativo_negociacao`,
                  fn: function({ item }) {
                    return [
                      item.st_ativo_negociacao === 0
                        ? _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-pause-circle-outline")
                          ])
                        : _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-play-circle-outline")
                          ])
                    ]
                  }
                },
                {
                  key: `item.st_mensageria`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.st_mensageria
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(" mdi-email-newsletter ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.st_mensageria
                                    ? "Apresentante apto para Mensageria"
                                    : "Apresentante requer ajustes para habilitar Mensageria"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: `item.notifica_company_id`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.notifica_company_id
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.notifica_company_id
                                                  ? "mdi-check-bold"
                                                  : "mdi-close-circle-outline"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.notifica_company_id
                                    ? "Apresentante habilitado na Notifica"
                                    : "Apresentante não habilitado na Notifica"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: `item.st_protestado`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.st_protestado
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.st_protestado
                                                  ? "mdi-check-bold"
                                                  : "mdi-close-circle-outline"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.st_protestado
                                    ? "Apresentante habilitado na Protestado"
                                    : "Apresentante não habilitado na Protestado"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: `item.is_admin`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          style: {
                            color: item.is_admin != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.is_admin)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 grey white--text",
                          attrs: {
                            elevation: "0",
                            width: "90%",
                            loading: _vm.dialogLoading || _vm.loading,
                            "data-cy": `editarApresentante${props.index}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(props.item)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm.snackbar
                ? _c("Snackbar", {
                    attrs: {
                      mostrarSnackbar: _vm.snackbar,
                      timeoutSnackbar: 4000,
                      corSnackbar: _vm.snackbarColor,
                      mensagemSnackbar: _vm.mensagem
                    },
                    on: {
                      fecharSnackbar: function($event) {
                        _vm.snackbar = false
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [_vm._v("Criar Apresentante")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c("CriarApresentante", {
                        attrs: {
                          apresentante: _vm.apresentante,
                          listaTiposApresentante: _vm.listaTiposApresentante
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          criarApresentante: _vm.verificaApresentante,
                          fecharModal: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "green white--text",
                            "data-cy": "salvarApresentante",
                            disabled: _vm.isInvalid,
                            width: "120"
                          },
                          on: { click: _vm.verificaApresentante }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: { dense: "", color: "error", width: "120" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogEditar
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogEditar,
                callback: function($$v) {
                  _vm.dialogEditar = $$v
                },
                expression: "dialogEditar"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialogEditar }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [_vm._v("Editar Apresentante")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c("EditarApresentante", {
                        attrs: {
                          apresentante: _vm.apresentante,
                          "apresentante-entidade": _vm.apresentanteEntidade,
                          "ativo-protestado": _vm.ativoProtestado,
                          loading: _vm.loading,
                          listaTiposApresentante: _vm.listaTiposApresentante
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          salvarApresentante: _vm.alterarApresentante,
                          fecharModal: _vm.closeDialogEditar,
                          loadingFalse: _vm.loadingFalse,
                          atualizarApresentante: function($event) {
                            _vm.apresentante = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "green white--text",
                            "data-cy": "alterarApresentante",
                            disabled: _vm.isInvalid,
                            width: "120"
                          },
                          on: { click: _vm.alterarApresentante }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: { dense: "", color: "error", width: "120" },
                          on: { click: _vm.closeDialogEditar }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800px",
            persistent: "",
            scrollable: "",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogCriarApresentanteExperience,
            callback: function($$v) {
              _vm.dialogCriarApresentanteExperience = $$v
            },
            expression: "dialogCriarApresentanteExperience"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              ;(_vm.dialogCriarApresentanteExperience = false),
                                (_vm.feedbackExperience = "")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-toolbar-title", [
                        _vm._v("Criar Apresentante Experience")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _vm.loadingCriarApresentanteExperience
                ? _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { color: "primary", indeterminate: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-center" },
                            [_vm._v(" Enviando... ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : !_vm.loadingCriarApresentanteExperience &&
                  _vm.feedbackExperience
                ? _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", [_vm._v(_vm._s(_vm.feedbackExperience))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", [
                              _vm._v(
                                "Você gostaria de criar um apresentante Experience BVS ou Recuperi?"
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "float-right",
                                  attrs: {
                                    color: "primary",
                                    "data-cy": "criarApresentanteExperienceBVS"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.CriarApresentanteExperience(
                                        "bvs"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" BVS ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    "data-cy":
                                      "criarApresentanteExperienceRecuperi"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.CriarApresentanteExperience(
                                        "recuperi"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Recuperi ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }